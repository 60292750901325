import EmailCopyOnly from "../../assets/images/copy-only.png";
import SendingEmails from "../../assets/images/sending-emails.jpeg";
import FullService from "../../assets/images/full-service.png";
import "./Services.css";

function Services() {
  return (
    <>
      <section className="container services pt-5 pb-2" id="services">
        {/* Title */}
        <div className="row servicesTitle pt-3">
          <div className="col d-flex justify-content-center">
            <p className="servicesTitleText  text-center fs-2">services</p>
          </div>
        </div>
        {/* Subtitle */}
        <div className="row servicesSubTitle">
          <div className="col d-flex justify-content-center">
            <p className="text-uppercase text-center fs-6">
              we understand that one size doesn't fit all. that's why we offer
              customized solutions that fit your brand's unique personality and
              goals.
            </p>
          </div>
        </div>
      </section>
      <section className="container d-lg-flex py-4">
        {/* Card */}
        <div className="col container card servicesCard d-flex align-items-center py-4 my-2">
          <img
            src={EmailCopyOnly}
            className="card-img-top2"
            alt="Desk with papers and a person writing notes with the CR Connects Logo"
          />
          <div className="card-body">
            <p className="container card-title2 text-uppercase text-center">
              email copy only
            </p>
            <p className="container card-text2 text-center">
              CR Connects will create the email copy text files and provide them
              to you. Your team will then be in control of sending the emails
              out to your list.
            </p>
          </div>
        </div>
        {/* Card */}
        <div className="col container card servicesCard d-flex align-items-center py-4 my-2 mx-lg-4 mx-xl-6">
          <img
            src={SendingEmails}
            className="card-img-top2"
            alt="laptop computer with someone typing with the CR Connects Logo"
          />
          <div className="card-body">
            <p className="container card-title2 text-uppercase text-center">
              sending emails
            </p>
            <p className="container card-text2 text-center">
              Including the Email Copy section + CR Connects will also send the
              emails to your client list. This service does not include list
              management.
            </p>
          </div>
        </div>
        {/* Card */}
        <div className="col container card servicesCard d-flex align-items-center py-4 my-2">
          <img
            src={FullService}
            className="card-img-top2"
            alt="Person looking at a cell phone with the CR Connects Logo"
          />
          <div className="card-body">
            <p className="container card-title2 text-uppercase text-center">
              full service
            </p>
            <p className="container card-text2 text-center">
              Including the Sending Emails + CR Connects will manage your entire
              email list. Ranging from creating funnels, autoresponders, and
              designing and testing the content.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Services;
