// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq {
  background-color: #ffffff;
}

.faq-title-text {
  font-family: "Logirent";
  letter-spacing: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/FAQ/FAQ.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;EACvB,sBAAsB;AACxB","sourcesContent":[".faq {\n  background-color: #ffffff;\n}\n\n.faq-title-text {\n  font-family: \"Logirent\";\n  letter-spacing: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
