import Logo1 from "../../assets/logo/CR Connects Without Slogan Pride.png";
import "./Header.css";

function Header() {
  return (
    <header className="navbar navbar-expand-lg">
      <div className="container d-flex">
        <a href="/" className="navbar-brand ">
          <img src={Logo1} alt="CR Connects" className="img-fluid logo " />
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle Navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul className="navbar-nav">
            <li className="nav-item pe-3">
              <a href="/" className="nav-link">
                Home
              </a>
            </li>

            <li className="nav-item">
              <a href="/contact" className="nav-link">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;
