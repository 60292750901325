// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.optIn {
  background-color: #383838;
}

.footer-title {
  color: #383838;
  letter-spacing: 0.125rem;
}

.footer-title-text {
  font-family: "Logirent";
  color: #f4400d;
}

.footer-list-item {
  border: none;
}

.useful-link {
  color: #2f2a76;
}

.useful-link:hover {
  color: #f4400d;
}

.social-link {
  color: #2f2a76;
}

.social-link:hover {
  color: #f4400d;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,wBAAwB;AAC1B;;AAEA;EACE,uBAAuB;EACvB,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".optIn {\n  background-color: #383838;\n}\n\n.footer-title {\n  color: #383838;\n  letter-spacing: 0.125rem;\n}\n\n.footer-title-text {\n  font-family: \"Logirent\";\n  color: #f4400d;\n}\n\n.footer-list-item {\n  border: none;\n}\n\n.useful-link {\n  color: #2f2a76;\n}\n\n.useful-link:hover {\n  color: #f4400d;\n}\n\n.social-link {\n  color: #2f2a76;\n}\n\n.social-link:hover {\n  color: #f4400d;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
