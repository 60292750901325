import { HashLink } from "react-router-hash-link";
import LogoMain from "../../assets/logo/logo-pride-translucient.png";
import "./Hero.css";

function Hero() {
  return (
    <section className="container pb-4">
      <div className="row hero">
        <div className="col d-flex flex-column justify-content-center">
          <img
            src={LogoMain}
            alt="CR Connects Crafting Words - Connecting Worlds"
            className="img-fluid crLogo"
          />
        </div>
      </div>
      <div className="row">
        <div className="col d-flex justify-content-center ">
          <a
            href="https://cr-connects.ck.page/cf3e8c56cd"
            target="-blank"
            rel="noreferrer"
          >
            <button className="btn btn-lg btnMain mx-2" type="button">
              SIGN UP
            </button>
          </a>
          <HashLink to="/#services">
            <button className="btn btn-lg btnSecondary mx-3" type="button">
              Learn More
            </button>
          </HashLink>
        </div>
      </div>
    </section>
  );
}

export default Hero;
