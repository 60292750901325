import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Home from "./pages/Home/Home";
import Contact from "./pages/Contact/Contact";
import FAQ from "./pages/FAQ/FAQ";
import Footer from "./components/Footer/Footer";
import SubFooter from "./sections/SubFooter/SubFooter";
import "./App.css";

function App() {
  document.title = "CR Connects";
  return (
    <>
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/faq" element={<FAQ />} />
        </Routes>
      </BrowserRouter>
      <Footer />
      <section className="subFooter">
        <SubFooter />
      </section>
    </>
  );
}

export default App;
