import React, { useEffect } from "react";
import Hero from "../../sections/Hero/Hero";
import WhatWeDo from "../../sections/WhatWeDo/WhatWeDo";
import Services from "../../sections/Services/Services";
import Prices from "../../sections/Prices/Prices";
import "./Home.css";

function Home() {
  useEffect(() => {
    document.title = "CR Connects | Home";
  }, []);
  return (
    <>
      <section className="hero">
        <Hero />
      </section>

      <section className="whatWeDo">
        <WhatWeDo />
      </section>

      <section className="services">
        <Services />
      </section>

      <section className="prices">
        <Prices />
      </section>
    </>
  );
}

export default Home;
