import Checkmark from "../../assets/icons/check.svg";
import "./Prices.css";

function Prices() {
  return (
    <>
      <div className="row pricesTitle pt-5">
        <div className="col d-flex justify-content-center">
          <p className="pricesTitleText  text-center fs-2">packages</p>
        </div>
      </div>
      <section className="container d-lg-flex pt-4 pb-5">
        {/* Card */}
        <div className="col card pricesCard d-flex align-items-center py-4 my-5">
          <div className="card-body3  d-flex flex-column">
            <p className="container card-title3 text-uppercase text-decoration-underline fs-3">
              copy only
            </p>
            <p className="container d-flex flex-column align-items-center card-text ">
              Per Email Text File
            </p>
            <a
              href="/contact"
              className="d-flex justify-content-center text-decoration-none"
            >
              <button className="btn btn-lg btnMain" type="button">
                LEARN MORE
              </button>
            </a>
          </div>
          <br />
          <div className="cardList">
            <ul className="list-group">
              <li className="list-group-item">
                <img
                  src={Checkmark}
                  className="checkmark img-fluid border border-dark rounded-circle p-1 me-2"
                  alt="Checkmark"
                />
                Planning session with CR Connects
              </li>

              <li className="list-group-item">
                <img
                  src={Checkmark}
                  className="checkmark img-fluid border border-dark rounded-circle p-1 me-2"
                  alt="Checkmark"
                />
                Set number of text files per week or month
              </li>
              <li className="list-group-item">
                <img
                  src={Checkmark}
                  className="checkmark img-fluid border border-dark rounded-circle p-1 me-2"
                  alt="Checkmark"
                />
                Customized text files sent to you
              </li>
              <li className="list-group-item">
                <img
                  src={Checkmark}
                  className="checkmark img-fluid border border-dark rounded-circle p-1 me-2"
                  alt="Checkmark"
                />
                You maintain control of when and how to send to your list
              </li>
              <li className="list-group-item">
                <img
                  src={Checkmark}
                  className="checkmark img-fluid border border-dark rounded-circle p-1 me-2"
                  alt="Checkmark"
                />
                Offer a flat monthly commission for specific number of text
                files OR per text file commission
              </li>
              <li className="list-group-item">
                <img
                  src={Checkmark}
                  className="checkmark img-fluid border border-dark rounded-circle p-1 me-2"
                  alt="Checkmark"
                />
                No long-term commitment
              </li>
            </ul>
          </div>
        </div>
        {/* Card */}
        <div className="col card pricesCard d-flex align-items-center py-4 my-3 mx-lg-4">
          <div className="card-header-btn py-2 px-4">Recommended</div>
          <div className="card-body3  d-flex flex-column">
            <p className="container d-flex justify-content-center card-title3 text-uppercase text-decoration-underline fs-3">
              copy + email
            </p>
            <p className="container d-flex flex-column align-items-center card-text ">
              Per Email Written And Sent
            </p>
            <a
              href="/contact"
              className="d-flex justify-content-center text-decoration-none"
            >
              <button className="btn btn-lg btnMain" type="button">
                LEARN MORE
              </button>
            </a>
          </div>
          <br />
          <div className="cardList">
            <ul className="list-group">
              <li className="list-group-item">
                <img
                  src={Checkmark}
                  className="checkmark img-fluid border border-dark rounded-circle p-1 me-2"
                  alt="Checkmark"
                />
                Planning session with CR Connects
              </li>

              <li className="list-group-item">
                <img
                  src={Checkmark}
                  className="checkmark img-fluid border border-dark rounded-circle p-1 me-2"
                  alt="Checkmark"
                />
                Set number of text files per week or month
              </li>
              <li className="list-group-item">
                <img
                  src={Checkmark}
                  className="checkmark img-fluid border border-dark rounded-circle p-1 me-2"
                  alt="Checkmark"
                />
                Customized text files that CR Connects will send to your
                contacts
              </li>
              <li className="list-group-item">
                <img
                  src={Checkmark}
                  className="checkmark img-fluid border border-dark rounded-circle p-1 me-2"
                  alt="Checkmark"
                />
                Gain freedom by having CR Connects send the emails to your list
              </li>
              <li className="list-group-item">
                <img
                  src={Checkmark}
                  className="checkmark img-fluid border border-dark rounded-circle p-1 me-2"
                  alt="Checkmark"
                />
                Offer a flat monthly commission for specific number of text
                files and sent emails OR per text file and sent email commission
              </li>
              <li className="list-group-item">
                <img
                  src={Checkmark}
                  className="checkmark img-fluid border border-dark rounded-circle p-1 me-2"
                  alt="Checkmark"
                />
                No long-term commitment
              </li>
            </ul>
          </div>
        </div>
        {/* Card */}
        <div className="col card pricesCard d-flex align-items-center py-4 my-5">
          <div className="card-body3  d-flex flex-column">
            <p className="container card-title3 text-uppercase text-decoration-underline fs-3">
              full service
            </p>
            <p className="container d-flex flex-column align-items-center card-text ">
              Per Month
            </p>
            <a
              href="/contact"
              className="d-flex justify-content-center text-decoration-none"
            >
              <button className="btn btn-lg btnMain" type="button">
                LEARN MORE
              </button>
            </a>
          </div>
          <br />
          <div className="cardList">
            <ul className="list-group">
              <li className="list-group-item">
                <img
                  src={Checkmark}
                  className="checkmark img-fluid border border-dark rounded-circle p-1 me-2"
                  alt="Checkmark"
                />
                Planning session with CR Connects
              </li>

              <li className="list-group-item">
                <img
                  src={Checkmark}
                  className="checkmark img-fluid border border-dark rounded-circle p-1 me-2"
                  alt="Checkmark"
                />
                Set number of text files per week or month
              </li>
              <li className="list-group-item">
                <img
                  src={Checkmark}
                  className="checkmark img-fluid border border-dark rounded-circle p-1 me-2"
                  alt="Checkmark"
                />
                Customized text files sent to you
              </li>
              <li className="list-group-item">
                <img
                  src={Checkmark}
                  className="checkmark img-fluid border border-dark rounded-circle p-1 me-2"
                  alt="Checkmark"
                />
                Gain freedom by having CR Connects send the emails to your list
              </li>
              <li className="list-group-item">
                <img
                  src={Checkmark}
                  className="checkmark img-fluid border border-dark rounded-circle p-1 me-2"
                  alt="Checkmark"
                />
                Flat monthly commission for work determined in planning session
              </li>
              <li className="list-group-item">
                <img
                  src={Checkmark}
                  className="checkmark img-fluid border border-dark rounded-circle p-1 me-2"
                  alt="Checkmark"
                />
                Monthly commitment
              </li>
              <li className="list-group-item">
                <img
                  src={Checkmark}
                  className="checkmark img-fluid border border-dark rounded-circle p-1 me-2"
                  alt="Checkmark"
                />
                Funnel/ autoresponder creation and management
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default Prices;
