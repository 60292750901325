import { React } from "react";

function OptIn() {
  return (
    <section className="col container py-3 d-md-flex align-items-center justify-content-between">
      <h2 className="text-white text-uppercase fw-bold fs-4">
        subscribe to our list and get our FREE Welcome Email Template!
      </h2>

      <div>
        <a
          href="https://cr-connects.ck.page/cf3e8c56cd"
          target="-blank"
          rel="noreferrer"
        >
          <button className="btn btn-lg btnMain" type="button">
            Subscribe and Download
          </button>
        </a>
      </div>
    </section>
  );
}

export default OptIn;
