import Envelope from "../../assets/icons/envelope.svg";
import Watch from "../../assets/icons/watch.svg";
import DownArrow from "../../assets/icons/down-arrow.svg";
import "./WhatWeDo.css";

function WhatWeDo() {
  return (
    <>
      <section className="container whatWeDo pt-5 pb-2">
        {/* Title */}
        <div className="row whatWeDoTitle pt-3">
          <div className="col d-flex justify-content-center">
            <p className="whatWeDoTitleText  text-center fs-2 ">what we do</p>
          </div>
        </div>
        {/* Subtitle */}
        <div className="row whatWeDoSubTitle">
          <div className="col d-flex justify-content-center">
            <p className="text-uppercase text-center fs-5">
              crafting emails that captivate, convert, and connect
            </p>
          </div>
        </div>
      </section>
      <section className="container d-lg-flex align-items-center py-5">
        {/* Card */}
        <div className="col card d-flex align-items-center py-4 my-2">
          <img src={Envelope} className="card-img-top" alt="Envelope" />
          <div className="card-body d-flex flex-column">
            <p className="container d-flex justify-content-center card-title text-uppercase">
              email copy
            </p>
            <p className="container card-text text-center">
              CR Connects crafts email copy that will captivate your clients and
              improve CTR that convert to more sales.
            </p>
          </div>
        </div>
        {/* Card */}
        <div className="col card d-flex align-items-center py-4 my-2 mx-lg-4">
          <img src={Watch} className="card-img-top" alt="Watch" />
          <div className="card-body d-flex flex-column">
            <p className="container d-flex justify-content-center card-title text-uppercase">
              autoresponder
            </p>
            <p className="container card-text text-center">
              CR Connects can manage your email list and craft autoresponder and
              blasts so your clients stay connected and informed.
            </p>
          </div>
        </div>
        {/* Card */}
        <div className="col card d-flex align-items-center py-4 my-2">
          <img src={DownArrow} className="card-img-top" alt="Down Arrow" />
          <div className="card-body d-flex flex-column">
            <p className="container d-flex justify-content-center card-title text-uppercase">
              funnels
            </p>
            <p className="container card-text text-center">
              CR Connects can design custom funnels that guide your clients
              through your communication journey.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default WhatWeDo;
