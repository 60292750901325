// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.whatWeDoTitle {
  color: #383838;
  letter-spacing: 0.5rem;
}

.whatWeDoTitleText {
  font-family: "Logirent";
}

.whatWeDoSubTitle {
  letter-spacing: 0.25rem;
}

.card {
  border: 1px solid #2f2a76;
}

.card-img-top {
  background-color: #2f2a76;
  border-radius: 50%;
  width: 5rem;
  padding: 1rem;
}

.card-title {
  letter-spacing: 0.25rem;
  font-size: 1.5rem;
  color: #383838;
}

.card-text {
  color: #2f2a76;
  font-size: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/sections/WhatWeDo/WhatWeDo.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,WAAW;EACX,aAAa;AACf;;AAEA;EACE,uBAAuB;EACvB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB","sourcesContent":[".whatWeDoTitle {\n  color: #383838;\n  letter-spacing: 0.5rem;\n}\n\n.whatWeDoTitleText {\n  font-family: \"Logirent\";\n}\n\n.whatWeDoSubTitle {\n  letter-spacing: 0.25rem;\n}\n\n.card {\n  border: 1px solid #2f2a76;\n}\n\n.card-img-top {\n  background-color: #2f2a76;\n  border-radius: 50%;\n  width: 5rem;\n  padding: 1rem;\n}\n\n.card-title {\n  letter-spacing: 0.25rem;\n  font-size: 1.5rem;\n  color: #383838;\n}\n\n.card-text {\n  color: #2f2a76;\n  font-size: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
