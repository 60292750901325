import { useEffect } from "react";
import "./FAQ.css";

function FAQ() {
  useEffect(() => {
    document.title = "CR Connects | FAQ";
  });
  return (
    <section className="container col faq">
      <div className="row w-md-75 mx-md-auto d-flex flex-column align-items-center">
        <div className="col py-md-4">
          <p className="fs-1 faq-title-text text-center">faq</p>
        </div>
      </div>
      <div className="row container faq-list px-md-5 pb-5">
        <ol>
          <li>
            What's Included?
            <ul>
              <li>
                Email Only
                <ul>
                  <li>
                    Maintain control of when and how to send to your list. With
                    our Email Only service, you'll receive raw text files
                    tailored for your campaigns. During our consultation calls,
                    content will be dicided. It's your responsibility to input
                    the text file into your email campaign software. CR Connects
                    won't handle sending emails, managing reponses, or
                    monitoring campaign stats.
                  </li>
                </ul>
              </li>
            </ul>
            <br />
            <ul>
              <li>
                Email and Send
                <ul>
                  <li>
                    Gain freedom by having CR Connects send the emails to your
                    list. Opt-in for Email and Send, and we'll create the raw
                    text files, content decided on the content through
                    consultation, and CR Connects takes care of inputting it
                    into your email software and sending emails to your list.
                    However, we won't manage responses, monitor stats, or
                    oversee autoresponders and funnels.
                  </li>
                </ul>
              </li>
            </ul>
            <br />
            <ul>
              <li>
                Full Service
                <ul>
                  <li>
                    Let CR Connects do the dirty work for you. With Full
                    Service, we handle everything from creating and sending text
                    files to managing responses and monitoring campaign stats.
                    We also oversee your autoresponders and funnels. This option
                    is designed to alleviate the stress of managing your email
                    list, allowing you to focus on strategy while working
                    closely with CR Connects.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <br />
          <li>
            How Much Does It Cost?
            <ul>
              <li>
                Email Only
                <ul>
                  <li>
                    The cost for raw text files involves a flat commission per
                    text file, adjusted based on the number and delivery
                    deadlines agreed upon during the consultation email.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                Email and Send
                <ul>
                  <li>
                    An additional commission is added to the Email Only amount,
                    set during the consultation meeting.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                Full Service
                <ul>
                  <li>
                    Full Service entails a flat monthly commission decided
                    during the consultation, depending on text files, emails
                    sent, funnels created, and autoresponder sequence length.
                    Additionally, there's an extra commission based on
                    percentage earnings from campaigns, determined in the
                    consultation.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                NOTE
                <ul>
                  <li>
                    These are the standard forms of payment, but we can discuss
                    additional options during the consultation to meet your
                    business needs.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <br />
          <li>
            Am I In A Contract?
            <ul>
              <li>
                At CR Connects, we don't believe in forcing long-term
                commitments. All negotiations are open and can be adjusted by
                either party during our business relationship. There's no
                obligation to maintain the partnership, and you're free to end
                it if you feel unsatisfied. Similarly, if mutual respect,
                honesty, and professionalism aren't met, CR Connects reserves
                the right to terminate agreements.
              </li>
            </ul>
          </li>
          <br />
          <li>
            How Are The Text Files Sent?
            <ul>
              <li>
                All text files will be sent via email or secure file sharing,
                determined during our consultation.
              </li>
            </ul>
          </li>
          <br />
          <li>
            Do I Get To Ask You Questions Specifically?
            <ul>
              <li>
                For all questions, comments, concerns, or requests, reach out to
                contact@crconnects.com, and someone will respond as soon as
                possible.
              </li>
            </ul>
          </li>
        </ol>
        <br />
      </div>
    </section>
  );
}

export default FAQ;
