import emailjs from "emailjs-com";
import { useState, useEffect, React } from "react";
import "./Contact.css";

const Contact = () => {
  useEffect(() => {
    document.title = "CR Connects | Contact";
  });
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = (e) => {
    alert(
      "Thank you for your email. We are reviewing your information and will be in contact within 24-48 business hours. Thank you, CR Connects"
    );
    e.preventDefault();
    setName("");
    setEmail("");
    setMessage("");

    emailjs
      .sendForm(
        "service_dfi539c",
        "template_lbln81h",
        e.target,
        "cDi0ew64g_jOxUBqJ"
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <section className="container contact p-5">
        <div className="row w-md-75 mx-md-auto d-flex flex-column align-items-center">
          <div className="col py-md-5">
            <p className="fs-1 text-center title">contact us today</p>
            <p className="fs-4 text-uppercase text-center subTitle">
              we're excited to speak with you
            </p>
            <p className="fs-6 text-center">
              If you have any questions or inquires about our products or
              services, our team is here to assist you!
            </p>
          </div>
          <hr />
        </div>
        <form className="row g-3 py-3 my-3" onSubmit={sendEmail}>
          <div className="col-md-6">
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              className="form-control"
              placeholder="First and Last Name"
              onChange={(e) => setName(e.target.value)}
              value={name}
              required
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="emailInfo" className="form-label">
              E-mail
            </label>
            <input
              type="email"
              name="email"
              className="form-control"
              id="emailInfo"
              placeholder="contact@test.com"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
            />
          </div>
          <div className="col-md-12">
            <label htmlFor="message" className="form-label">
              Message
            </label>
            <textarea
              className="form-control"
              name="message"
              id="message"
              rows="3"
              placeholder="Message"
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            ></textarea>
          </div>
          <div className="col">
            <button type="submit" className="btn btn-lg btnMain">
              Send
            </button>
          </div>
        </form>
        <hr />
      </section>
    </>
  );
};

export default Contact;
