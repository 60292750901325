// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.services {
  color: #ffffff;
}

.servicesTitle {
  letter-spacing: 0.5rem;
}

.servicesTitleText {
  font-family: "Logirent";
}

.servicesSubTitle {
  letter-spacing: 0.25rem;
}

.servicesCard {
  background-color: #192024;
  border: none;
}

.card-img-top2 {
  width: 20rem;
  padding: 1rem;
}

.card-title2 {
  letter-spacing: 0.25rem;
  font-size: 1.5rem;
  color: #ffffff;
}

.card-text2 {
  font-size: 1rem;
  color: #ffffff;
}
`, "",{"version":3,"sources":["webpack://./src/sections/Services/Services.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,uBAAuB;EACvB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB","sourcesContent":[".services {\n  color: #ffffff;\n}\n\n.servicesTitle {\n  letter-spacing: 0.5rem;\n}\n\n.servicesTitleText {\n  font-family: \"Logirent\";\n}\n\n.servicesSubTitle {\n  letter-spacing: 0.25rem;\n}\n\n.servicesCard {\n  background-color: #192024;\n  border: none;\n}\n\n.card-img-top2 {\n  width: 20rem;\n  padding: 1rem;\n}\n\n.card-title2 {\n  letter-spacing: 0.25rem;\n  font-size: 1.5rem;\n  color: #ffffff;\n}\n\n.card-text2 {\n  font-size: 1rem;\n  color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
