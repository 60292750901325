// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact {
  background-color: #ffffff;
}

.title {
  color: #383838;
  font-family: "Logirent";
}
`, "",{"version":3,"sources":["webpack://./src/pages/Contact/Contact.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,uBAAuB;AACzB","sourcesContent":[".contact {\n  background-color: #ffffff;\n}\n\n.title {\n  color: #383838;\n  font-family: \"Logirent\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
