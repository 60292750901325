// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pricesTitle {
  letter-spacing: 0.5rem;
}

.pricesTitleText {
  color: #383838;
  font-family: "Logirent";
}

.pricesCard {
  background-color: #eeeeee;
}

.cardList {
  width: 100%;
}

.list-group-item {
  background-color: #eeeeee;
  font-size: 0.75rem;
  color: #2f2a76;
}

.card-title3 {
  color: #383838;
}

.card-body3 {
  margin-bottom: 0;
}

.card-header-btn {
  background-color: #2f2a76;
  color: #ffffff;
  border-radius: 2rem;
  position: absolute;
  top: -1rem;
}
`, "",{"version":3,"sources":["webpack://./src/sections/Prices/Prices.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,mBAAmB;EACnB,kBAAkB;EAClB,UAAU;AACZ","sourcesContent":[".pricesTitle {\n  letter-spacing: 0.5rem;\n}\n\n.pricesTitleText {\n  color: #383838;\n  font-family: \"Logirent\";\n}\n\n.pricesCard {\n  background-color: #eeeeee;\n}\n\n.cardList {\n  width: 100%;\n}\n\n.list-group-item {\n  background-color: #eeeeee;\n  font-size: 0.75rem;\n  color: #2f2a76;\n}\n\n.card-title3 {\n  color: #383838;\n}\n\n.card-body3 {\n  margin-bottom: 0;\n}\n\n.card-header-btn {\n  background-color: #2f2a76;\n  color: #ffffff;\n  border-radius: 2rem;\n  position: absolute;\n  top: -1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
