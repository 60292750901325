// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btnMain {
  background-color: #f43f0dd0;
  color: #ffffff;
  border: #f4400d;
}

.btnMain:hover {
  background-color: #f4400d;
  color: #ffffff;
}

.btnSecondary {
  background-color: #ffffff;
  color: #2f2a76;
  border: 1px solid #2f2a76;
}

.btnSecondary:hover {
  background: #2f2a76;
  color: #ffffff;
}
`, "",{"version":3,"sources":["webpack://./src/sections/Hero/Hero.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,cAAc;AAChB","sourcesContent":[".btnMain {\n  background-color: #f43f0dd0;\n  color: #ffffff;\n  border: #f4400d;\n}\n\n.btnMain:hover {\n  background-color: #f4400d;\n  color: #ffffff;\n}\n\n.btnSecondary {\n  background-color: #ffffff;\n  color: #2f2a76;\n  border: 1px solid #2f2a76;\n}\n\n.btnSecondary:hover {\n  background: #2f2a76;\n  color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
