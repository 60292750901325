import OptIn from "../../sections/OptIn/OptIn";

import "./Footer.css";

function Footer() {
  return (
    <>
      <section className="optIn">
        <OptIn />
      </section>

      <footer className="footer">
        <section className="container d-md-flex justify-content-md-between pt-4">
          {/* About */}

          <div className="col container my-2">
            <p className="footer-title fs-3">
              ABOUT{" "}
              <span className="footer-title footer-title-text">
                CR connects
              </span>
            </p>
            <p>
              At CR Connects, we're passionate about the power of words. We
              believe that every message holds the potential to captivate,
              convert, and connect with your audience. Whether you're a startup
              or an established brand, we're here to weave the perfect narrative
              for you.
            </p>
          </div>

          {/* Resources */}

          <div className="col container my-2">
            <p className="text-uppercase footer-title fs-3">resources</p>
            <ul className="list-group">
              <li className="list-group-item footer-list-item border-bottom px-0">
                <a href="/faq" className="text-decoration-none useful-link">
                  FAQ
                </a>
              </li>
              <li className="list-group-item footer-list-item border-bottom px-0">
                <a href="/contact" className="text-decoration-none useful-link">
                  Contact
                </a>
              </li>
              <li className="list-group-item footer-list-item border-bottom px-0">
                <a
                  href="https://cr-connects.ck.page/49a915d0dd"
                  target="_blank"
                  rel="noreferrer"
                  className="text-decoration-none useful-link"
                >
                  Social Links
                </a>
              </li>
            </ul>
          </div>
        </section>
      </footer>
    </>
  );
}

export default Footer;
